/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useMemo, useState } from 'react';

import { AutoComponentBrowserContext, AUTO_COMPONENT_BROWSER_KEY } from './Context';

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * }} AutoComponentBrowserProviderProps
 */

/** @type {React.FC<AutoComponentBrowserProviderProps>} */
export const AutoComponentBrowserProvider = ({ children }) => {
	const [path, setPath] = useState(
		/** @type {string | undefined} */(undefined),
	);

	const clear = useCallback(() => setPath(undefined), []);

	useEffect(() => {
		const localStoragePath = localStorage.getItem(AUTO_COMPONENT_BROWSER_KEY);
		if (localStoragePath) {
			setPath(localStoragePath);
			localStorage.removeItem(AUTO_COMPONENT_BROWSER_KEY);
		}
	}, []);

	const value = useMemo(() => ({
		clear,
		path,
	}), [
		clear,
		path,
	]);

	return (
		<AutoComponentBrowserContext.Provider value={value}>
			{children}
		</AutoComponentBrowserContext.Provider>
	);
};
