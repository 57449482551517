// @ts-check

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';

import * as socialNetworksApi from '../../api/channel/socialNetworks';
import { axiosQueryWrapper, axiosMutationWrapper } from '../utils/axios-wrapper';

export const SOCIAL_NETWORKS_QUERY_KEYS = {
	fetchSocialNetworks: () => ['api', 'channel', 'socialNetworks', 'fetch'],
	fetchVideoWithSocialNetworkUploads: (videoId) => ['api', 'channel', 'socialNetworks', 'videos', videoId],
};

export const useFetchSocialNetworks = () => useQuery(
	SOCIAL_NETWORKS_QUERY_KEYS.fetchSocialNetworks(),
	axiosQueryWrapper(socialNetworksApi.fetchSocialNetworks),
);

export const useLinkSocialNetwork = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(socialNetworksApi.linkSocialNetwork), {
			onSuccess: () => {
				queryClient.invalidateQueries(SOCIAL_NETWORKS_QUERY_KEYS.fetchSocialNetworks());
			},
		},
	);
};

export const useUploadVideoToSocialNetwork = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(socialNetworksApi.uploadVideoToSocialNetwork), {
			onSuccess: (updatedVideo) => {
				queryClient.setQueryData(
					SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(updatedVideo._id),
					updatedVideo,
				);
			},
		},
	);
};

export const useHandleVideoSocialNetworkUploadsStatusChange = () => {
	const queryClient = useQueryClient();

	return useCallback((updatedVideo) => {
		// Only update if social network uploads are populated
		if (updatedVideo.socialNetworkUploads?.some((u) => typeof u.socialNetwork === 'object')) {
			queryClient.setQueryData(
				SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(updatedVideo._id),
				updatedVideo,
			);
		}
	}, [queryClient]);
};

export const useFetchVideoWithSocialNetworkUploads = (videoId) => useQuery(
	SOCIAL_NETWORKS_QUERY_KEYS.fetchVideoWithSocialNetworkUploads(videoId),
	axiosQueryWrapper(socialNetworksApi.fetchVideoWithSocialNetworkUploads, videoId),
);
