import PropTypes from 'prop-types';
import { lazy } from 'react';
import { NavItem, NavLink, TabContent } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useFetchProfile } from '../../api-hooks/profile/me';
import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader, ModalScreenHeaderNav } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { MyAccountModalTab, useUserSettings } from './Context';
import { ProfileRewardNotice } from './ProfileRewardNotice';

const MyAccountModalContent = lazy(() => import('./MyAccountModalContent'));

export const MyAccountModal = ({ isOpen, activeTab, setActiveTab }) => {
	const { t } = useTranslation();
	const { closeModal } = useUserSettings();
	const { data: profile } = useFetchProfile();

	return (
		<ModalScreen
			bodyClassName="py-0"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('UserSettings.MyAccountModal.myAccount')}
					notice={!profile?.completedRewardClaimed && !profile?.linkedOrganization
						? <ProfileRewardNotice />
						: undefined}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyAccountModal.details')}
								onClick={() => setActiveTab(MyAccountModalTab.DETAILS)}
								active={activeTab === MyAccountModalTab.DETAILS}
							>
								<span>{t('UserSettings.MyAccountModal.details')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						{!profile?.linkedOrganization && (
							<NavItem className="mr-3">
								<NavLink
									title={t('UserSettings.MyAccountModal.settings')}
									onClick={() => setActiveTab(MyAccountModalTab.SETTINGS)}
									active={activeTab === MyAccountModalTab.SETTINGS}
								>
									<span>{t('UserSettings.MyAccountModal.settings')}</span>
									<div className="divider" />
								</NavLink>
							</NavItem>
						)}
						<NavItem className="mr-3">
							<NavLink
								title="Linked accounts"
								onClick={() => setActiveTab(MyAccountModalTab.LINKED_SOCIAL_NETWORKS)}
								active={activeTab === MyAccountModalTab.LINKED_SOCIAL_NETWORKS}
							>
								<span>LINKED ACCOUNTS</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyAccountModal.operators')}
								onClick={() => setActiveTab(MyAccountModalTab.OPERATORS)}
								active={activeTab === MyAccountModalTab.OPERATORS}
							>
								<span>{t('UserSettings.MyAccountModal.operators')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyAccountModal.qrcode')}
								onClick={() => setActiveTab(MyAccountModalTab.QRCODE)}
								active={activeTab === MyAccountModalTab.QRCODE}
							>
								<span>{t('UserSettings.MyAccountModal.qrcodeMaj')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyAccountModal.preference')}
								onClick={() => setActiveTab(MyAccountModalTab.PREFERENCES)}
								active={activeTab === MyAccountModalTab.PREFERENCES}
							>
								<span>{t('UserSettings.MyAccountModal.preference')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mr-3">
							<NavLink
								title={t('UserSettings.MyAccountModal.blockedChannels')}
								onClick={() => setActiveTab(MyAccountModalTab.BLOCKED_CHANNELS)}
								active={activeTab === MyAccountModalTab.BLOCKED_CHANNELS}
							>
								<span>{t('UserSettings.MyAccountModal.blockedChannels')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={closeModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<MyAccountModalContent activeTab={activeTab} closeModal={closeModal} />
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

MyAccountModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	activeTab: PropTypes.oneOf(Object.values(MyAccountModalTab)),
	setActiveTab: PropTypes.func,
};

MyAccountModal.defaultProps = {
	activeTab: MyAccountModalTab.DETAILS,
	setActiveTab: PropTypes.func,
};
