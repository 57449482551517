/* eslint-disable react/prop-types */
// @ts-check
import {
	memo,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useInputs } from '../Inputs/Context';
import { MediaUserPermissionModal } from './UserPermissionModal';
import { MediaUserPermissionStatus } from './UserAccessStatus';
import { Resolution, stopTrack } from './utils';
import { MediaUserPerform } from './UserPerformer';

/*
	My advice: don't touch it unless this is already the end of world.
	And if so, keep in mind that Safari won't fight on your side...
*/

/**
 * @typedef {{
 * addTracks: (
 * 	tracks: UserMediaStreamTrack[], configId: number, kind: MediaStreamTrack['kind']
 * ) => void,
 * deleteConfigTracks: (configId: number) => void,
 * getIsUserAudioActive: (configId: number) => boolean,
 * getIsUserVideoActive: (configId: number) => boolean,
 * removeTracksByConfigId: (configId: number, kind: MediaStreamTrack['kind']) => void,
 * toggleAudio: (configId: number) => void,
 * toggleVideo: (configId: number) => void,
 * userActiveTracks: UserMediaStreamTrack[],
 * userAudioActiveTracks: UserMediaStreamTrack[],
 * userAudioPromptStatus: MediaUserPermissionStatus,
 * userAudioRequestErrors: MediaRequestError[],
 * userMediastreams: UserMediaStream[],
 * userVideoActiveTracks: UserMediaStreamTrack[],
 * userVideoPromptStatus: MediaUserPermissionStatus,
 * userVideoRequestErrors: MediaRequestError[],
 * }} IMediaUserContext
 */

export const MediaUserContext = createContext(/** @type {IMediaUserContext} */({}));

export const useMediaUser = () => useContext(MediaUserContext);

/**
 * @typedef {{
 * 	configId: number,
 *  error: Error,
 * }} MediaRequestError
 */

/**
 * @typedef {MediaStream & {
 * 	configId?: number,
* }} UserMediaStream
*/

/**
 * @typedef {MediaStreamTrack & {
 * 	configId?: number,
* }} UserMediaStreamTrack
*/

/**
 * @typedef {{
 * 	allowAudio?: boolean,
 * 	allowVideo?: boolean,
 * 	children: React.ReactNode,
 *  resolution?: Resolution,
 * }} MediaUserProps
 */

// eslint-disable-next-line prefer-arrow-callback
export const MediaUser = memo(function MediaUser(
	/** @type {MediaUserProps} */
	{
		allowAudio = false,
		allowVideo = false,
		children,
		resolution = Resolution.P720,
	},
) {
	const inputs = useInputs();
	const {
		activateAudioInput,
		activateVideoInput,
		deactivateAudioInput,
		deactivateVideoInput,
		inputsConfig,
	} = inputs;

	const [userAudioRequestErrors, setUserAudioRequestErrors] = useState(
		/** @type {MediaRequestError[]} */([]),
	);
	const [userVideoRequestErrors, setUserVideoRequestErrors] = useState(
		/** @type {MediaRequestError[]} */([]),
	);

	const [userAudioPromptStatus, setUserAudioPromptStatus] = useState(
		MediaUserPermissionStatus.INITIAL,
	);
	const [userVideoPromptStatus, setUserVideoPromptStatus] = useState(
		MediaUserPermissionStatus.INITIAL,
	);

	const [userAudioActiveTracks, setUserAudioActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const [userVideoActiveTracks, setUserVideoActiveTracks] = useState(
		/** @type {UserMediaStreamTrack[]} */([]),
	);
	const userActiveTracks = useMemo(
		() => [...userAudioActiveTracks, ...userVideoActiveTracks],
		[userAudioActiveTracks, userVideoActiveTracks],
	);

	const setUserAudioRequestErrorByConfigId = useCallback((
		/** @type {Error} */error,
		/** @type {number} */configId,
	) => {
		setUserAudioRequestErrors((prevState) => (
			[
				...prevState.filter((request) => request.configId !== configId),
				{ configId, error },
			]
		));
	}, []);

	const setUserVideoRequestErrorByConfigId = useCallback((
		/** @type {Error} */error,
		/** @type {number} */configId,
	) => {
		setUserVideoRequestErrors((prevState) => (
			[
				...prevState.filter((request) => request.configId !== configId),
				{ configId, error },
			]
		));
	}, []);

	const addTracks = useCallback((
		/** @type {UserMediaStreamTrack[]} */tracks,
		/** @type {number} */configId,
		/** @type {MediaStreamTrack['kind']} */kind,
	) => {
		if (kind === 'audio') {
			setUserAudioActiveTracks((state) => [
				...(state.filter((t) => t.configId !== configId) || []),
				...tracks,
			]);
		} else if (kind === 'video') {
			setUserVideoActiveTracks((state) => [
				...(state.filter((t) => t.configId !== configId) || []),
				...tracks,
			]);
		} else {
			throw new Error(`addTracks: Unknown kind '${kind}'`);
		}
	}, [setUserVideoActiveTracks]);

	const removeTracksByConfigId = useCallback((
		/** @type {number} */configId,
		/** @type {MediaStreamTrack['kind']} */kind,
	) => {
		if (kind === 'audio' || !kind) {
			setUserAudioActiveTracks(
				(prevState) => prevState.filter((track) => track.configId !== configId),
			);
		} else if (kind === 'video' || !kind) {
			setUserVideoActiveTracks(
				(prevState) => prevState.filter((track) => track.configId !== configId),
			);
		} else {
			throw new Error(`addTracks: Unknown kind '${kind}'`);
		}
	}, [setUserVideoActiveTracks]);

	const getIsUserAudioActive = useCallback(
		(
			/** @type {number} */configId,
		) => !!(userAudioActiveTracks || []).find((track) => (
			track.configId === configId
		)),
		[userAudioActiveTracks],
	);
	const getIsUserVideoActive = useCallback(
		(
			/** @type {number} */configId,
		) => !!(userVideoActiveTracks || []).find((track) => (
			track.configId === configId
		)),
		[userVideoActiveTracks],
	);

	const useMediastreamsRef = useRef(
		/** @type {UserMediaStream[]}*/([]),
	); // memoize mediastreams

	const userMediastreams = useMemo(() => {
		if (userActiveTracks.length > 0) {
			const mediaStreams = inputsConfig.map((cfg) => {
				const tracks = userActiveTracks.filter((track) => track.configId === cfg.id);
				if (tracks.length <= 0) return undefined;

				const memoizedMediastream = useMediastreamsRef.current.find((m) => m.configId === cfg.id);
				const memoizedMediastreamTracks = memoizedMediastream?.getTracks() || [];
				if (
					tracks.length === memoizedMediastreamTracks?.length
					&& tracks.every((track) => memoizedMediastreamTracks.includes(track))
				) return memoizedMediastream; // return memoized mediastream if tracks are the same

				// Refresh mediastream when tracks change to avoid player image stuck
				/** @type {UserMediaStream} */
				const newMediaStream = new MediaStream(tracks);
				newMediaStream.configId = cfg.id;
				return newMediaStream;
			}).filter((m) => !!m); // remove undefined mediastreams (if no tracks per configId)

			useMediastreamsRef.current = mediaStreams;
			return mediaStreams;
		}
		return [];
	}, [userActiveTracks, inputsConfig]);

	const deleteConfigTracks = useCallback((
		/** @type {number} */configId,
	) => {
		setUserAudioActiveTracks((prevState) => [
			...prevState.filter((track) => track.configId !== configId),
		]);
		setUserVideoActiveTracks((prevState) => [
			...prevState.filter((track) => track.configId !== configId),
		]);
	}, []);

	const isVideoAllowed = allowVideo;

	const userActiveTracksRef = useRef(userActiveTracks);
	useEffect(() => { userActiveTracksRef.current = userActiveTracks; }, [userActiveTracks]);

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);

	useEffect(() => {
		const handleTrackEnded = (/** @type {Event}*/{ target: track }) => {
			if (!(track instanceof MediaStreamTrack)) return;

			track.removeEventListener('trackended', handleTrackEnded);
			if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
			if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
		};

		userActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			userActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [userActiveTracks]);

	const enableAudio = useCallback((
		/** @type {number} */configId,
	) => {
		if (allowAudio) {
			activateAudioInput(configId);
		}
	}, [
		activateAudioInput,
		allowAudio,
	]);

	const enableVideo = useCallback((
		/** @type {number} */configId,
	) => {
		if (isVideoAllowed) {
			activateVideoInput(configId);
		}
	}, [
		activateVideoInput,
		isVideoAllowed,
	]);

	const disableAudio = useCallback((
		/** @type {number} */configId,
	) => {
		deactivateAudioInput(configId);
	}, [deactivateAudioInput]);

	const disableVideo = useCallback((
		/** @type {number} */configId,
	) => {
		deactivateVideoInput(configId);
	}, [deactivateVideoInput]);

	const toggleAudio = useCallback((
		/** @type {number} */configId,
	) => {
		if (getIsUserAudioActive(configId)) disableAudio(configId);
		else enableAudio(configId);
	}, [disableAudio, enableAudio, getIsUserAudioActive]);

	const toggleVideo = useCallback((
		/** @type {number} */configId,
	) => {
		if (getIsUserVideoActive(configId)) disableVideo(configId);
		else enableVideo(configId);
	}, [disableVideo, enableVideo, getIsUserVideoActive]);

	const stopConfigTracks = useCallback((
		/** @type {number} */configId,
		/** @type {MediaStreamTrack['kind']} */kind,
	) => {
		let configTracks = (userActiveTracks || []).filter((track) => track.configId === configId);
		if (kind) {
			configTracks = configTracks.filter((track) => track.kind === kind);
		}
		configTracks.forEach((t) => { stopTrack(t); });
	}, [userActiveTracks]);

	// cleanup
	useEffect(() => () => {
		userActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const value = useMemo(() => ({
		addTracks,
		deleteConfigTracks,
		getIsUserAudioActive,
		getIsUserVideoActive,
		removeTracksByConfigId,
		toggleAudio,
		toggleVideo,
		userActiveTracks,
		userAudioActiveTracks,
		userAudioPromptStatus,
		userAudioRequestErrors,
		userMediastreams,
		userVideoActiveTracks,
		userVideoPromptStatus,
		userVideoRequestErrors,
	}), [
		addTracks,
		deleteConfigTracks,
		getIsUserAudioActive,
		getIsUserVideoActive,
		removeTracksByConfigId,
		toggleAudio,
		toggleVideo,
		userActiveTracks,
		userAudioActiveTracks,
		userAudioPromptStatus,
		userAudioRequestErrors,
		userMediastreams,
		userVideoActiveTracks,
		userVideoPromptStatus,
		userVideoRequestErrors,
	]);

	return (
		<MediaUserContext.Provider value={value}>
			{children}
			<MediaUserPermissionModal
				userAudioPromptStatus={userAudioPromptStatus}
				userVideoPromptStatus={userVideoPromptStatus}
			/>
			{inputsConfig.map((cfg) => (
				<MediaUserPerform
					key={cfg.id}
					isVideoAllowed={isVideoAllowed}
					allowAudio={allowAudio}
					addTracks={addTracks}
					inputConfig={cfg}
					resolution={resolution}
					removeTracksByConfigId={removeTracksByConfigId}
					userAudioRequestError={userAudioRequestErrors.find((e) => (
						e.configId === cfg.id && e.error
					))}
					userVideoRequestError={userVideoRequestErrors.find((e) => (
						e.configId === cfg.id && e.error
					))}
					setUserAudioPromptStatus={setUserAudioPromptStatus}
					setUserVideoPromptStatus={setUserVideoPromptStatus}
					setUserAudioRequestError={setUserAudioRequestErrorByConfigId}
					setUserVideoRequestError={setUserVideoRequestErrorByConfigId}
					stopConfigTracks={stopConfigTracks}
				/>
			))}
		</MediaUserContext.Provider>
	);
});
