// @ts-check

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { InputsContext } from './Context';
import { isIOS, isMobileOrIpad } from '../../lib/userAgent';
import getUserMedia from '../../lib/getUserMedia';

const LOCAL_STORAGE_INPUT_CONFIGS_KEY = 'beeyou_inputDevices';
const DEFAULT_CONFIG_ID = 0;
const DEFAULT_CONFIG_LABEL = `Source ${DEFAULT_CONFIG_ID + 1}`;
const INPUT_CONFIG_VERSION = 6;

/**
 * @typedef {{
 * 	label: string
 * 	id: number
 * 	audioInputOff: boolean
 * 	videoInputOff: boolean
 * 	audioInputId?: string
 * 	audioDeviceLabel?: string
 * 	videoInputId?: string
 * 	videoDeviceLabel?: string
 * }} InputConfig
 */

/**
 * @typedef {Pick<MediaDeviceInfo, 'deviceId'
* 	| 'groupId'
* 	| 'kind'
* 	| 'label'
* >} InputDeviceInfo
*/

const getSavedInputsConfig = () => {
	const savedConfig = localStorage.getItem(LOCAL_STORAGE_INPUT_CONFIGS_KEY);

	if (savedConfig) {
		/**
		 * @type {{
		 * 	inputsConfig: InputConfig[],
		 * 	__version: number,
		 * }}
		 * */
		const config = JSON.parse(savedConfig);

		// eslint-disable-next-line no-underscore-dangle
		if (config.__version !== INPUT_CONFIG_VERSION) {
			return undefined;
		}

		if (isIOS) {
			// iOS doesn't allow to have multiple cameras enabled at the same time
			return config.inputsConfig.filter((cfg) => cfg.id === DEFAULT_CONFIG_ID);
		}

		return config.inputsConfig;
	}
	return undefined;
};

/**
 * @param {InputConfig[]} inputsConfig
 * */
const saveInputsConfig = (inputsConfig) => localStorage.setItem(
	LOCAL_STORAGE_INPUT_CONFIGS_KEY,
	JSON.stringify({ inputsConfig, __version: INPUT_CONFIG_VERSION }),
);

const DEFAULT_DEVICE_ID = 'default';
const DEFAULT_DEVICE_LABEL = 'Default';

export const DEFAULT_DEVICES = {
	cam: {
		deviceId: 'default-cam',
		label: 'Default camera',
	},
	mic: {
		deviceId: 'default-mic',
		label: 'Default microphone',
	},
};

/** @enum {string} */
export const AVAILABLE_MOBILE_CAMS = {
	FRONT: 'user',
	BACK: 'environment',
};

/** @type {InputConfig} */
const DEFAULT_INPUT_CONFIG = {
	label: DEFAULT_CONFIG_LABEL,
	id: DEFAULT_CONFIG_ID,
	audioInputOff: false,
	videoInputOff: false,
	audioInputId: DEFAULT_DEVICES.mic.deviceId,
	audioDeviceLabel: DEFAULT_DEVICES.mic.label,
	videoInputId: DEFAULT_DEVICES.cam.deviceId,
	videoDeviceLabel: DEFAULT_DEVICES.cam.label,
};

/**
 * @param {InputDeviceInfo[]} devices
 * @param {string} deviceId
 * */
const findDeviceByDeviceId = (devices, deviceId) => {
	if (
		deviceId === DEFAULT_DEVICES.cam.deviceId
		|| deviceId === DEFAULT_DEVICES.mic.deviceId
	) {
		return devices[0];
	}
	return devices.find((device) => device.deviceId === deviceId);
};

/**
 * @typedef {{
 * 	children: React.ReactNode
 * }} InputsProps
 */

export const Inputs = (
	/** @type {InputsProps} */
	{ children },
) => {
	const [enabled, setEnabled] = useState(false);
	const savedInputsConfig = useMemo(() => getSavedInputsConfig(), []);

	const [inputDevices, setInputDevices] = useState(/** @type {InputDeviceInfo[]} */([]));
	const audioInputDevices = useMemo(() => inputDevices.filter((device) => device.kind === 'audioinput'), [inputDevices]);
	const videoInputDevices = useMemo(() => inputDevices.filter((device) => device.kind === 'videoinput'), [inputDevices]);
	const [defaultFrontDeviceId, setDefaultFrontDeviceId] = useState(
		/** @type {string | undefined} */(undefined),
	);
	const [defaultBackDeviceId, setDefaultBackDeviceId] = useState(
		/** @type {string | undefined} */(undefined),
	);
	const [defaultFaceingMode, setDefaultFaceingMode] = useState(AVAILABLE_MOBILE_CAMS.FRONT);

	const [inputsConfig, setInputsConfig] = useState(
		/** @type {InputConfig[]} */
		(savedInputsConfig?.length ? savedInputsConfig : [DEFAULT_INPUT_CONFIG]),
	);

	const currentlyEnabledDefaultDevices = useMemo(() => {
		const defaultInputConfig = inputsConfig[0];
		/** @type {InputDeviceInfo[]} */
		const enabledDefaultDevices = [];
		if (!defaultInputConfig) {
			return enabledDefaultDevices;
		}
		if (defaultInputConfig.audioInputId && !defaultInputConfig.audioInputOff) {
			const device = findDeviceByDeviceId(audioInputDevices, defaultInputConfig.audioInputId);
			if (device) enabledDefaultDevices.push(device);
		}
		if (defaultInputConfig.videoInputId && !defaultInputConfig.videoInputOff) {
			const device = findDeviceByDeviceId(videoInputDevices, defaultInputConfig.videoInputId);
			if (device) enabledDefaultDevices.push(device);
		}
		return enabledDefaultDevices.filter(Boolean);
	}, [audioInputDevices, inputsConfig, videoInputDevices]);

	const getAvailableInputId = useCallback((
		/** @type {string} */
		inputId,
		/** @type {number} */
		configId,
	) => {
		if (!inputDevices?.length || !inputId) return undefined;

		let enabledInput;

		if (inputId === DEFAULT_DEVICES.cam.deviceId) {
			[enabledInput] = inputDevices.filter(({ kind }) => kind === 'videoinput');
		} else if (inputId === DEFAULT_DEVICES.mic.deviceId) {
			[enabledInput] = inputDevices.filter(({ kind }) => kind === 'audioinput');
		} else {
			let availableDevices = inputDevices;
			if (configId !== 0) {
				// In case the device is already enabled as "default" device,
				// don't start twice the same device
				availableDevices = inputDevices.filter(({ deviceId }) => (
					!currentlyEnabledDefaultDevices.find((device) => device.deviceId === deviceId)
				));
			}

			enabledInput = availableDevices.find(({ deviceId }) => deviceId === inputId);
		}

		return enabledInput?.deviceId;
	}, [currentlyEnabledDefaultDevices, inputDevices]);

	const helpers = useMemo(() => {
		const setPartialInputsConfig = (
			/** @type {Partial<InputConfig>} */
			config,
		) => {
			setInputsConfig((s) => (
				s.map((cfg) => {
					if (cfg.id !== config.id) {
						return cfg;
					}
					return {
						...cfg,
						...config,
					};
				})
			));
		};

		return {
			setPartialInputsConfig,
			addNewConfig: (
				/** @type {{ label: string, id: number }} */
				{ label, id },
			) => {
				const firstAvailableAudioInputDevice = [...audioInputDevices]
					.filter((audioInputDevice) => !currentlyEnabledDefaultDevices
						.find((device) => device.deviceId === audioInputDevice.deviceId))
					.find(
						({ deviceId }) => !inputsConfig.find((c) => c.audioInputId === deviceId),
					);
				const firstAvailableVideoInputDevice = [...videoInputDevices]
					.filter((videoInputDevice) => !currentlyEnabledDefaultDevices
						.find((device) => device.deviceId === videoInputDevice.deviceId))
					.find(
						({ deviceId }) => !inputsConfig.find((c) => c.videoInputId === deviceId),
					);
				const config = {
					label,
					id,
					audioInputOff: false,
					videoInputOff: false,
					audioInputId: firstAvailableAudioInputDevice?.deviceId ?? undefined,
					audioDeviceLabel: firstAvailableAudioInputDevice?.label ?? undefined,
					videoInputId: firstAvailableVideoInputDevice?.deviceId ?? undefined,
					videoDeviceLabel: firstAvailableVideoInputDevice?.label ?? undefined,
				};
				setInputsConfig((s) => ([...s, { ...config }]));
			},
			activateAudioInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, audioInputOff: false });
			},
			activateVideoInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, videoInputOff: false });
			},
			deactivateAudioInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, audioInputOff: true });
			},
			deactivateVideoInput: (
				/** @type {number} */configId,
			) => {
				setPartialInputsConfig({ id: configId, videoInputOff: true });
			},
			deleteConfig: (
				/** @type {number} */configId,
			) => {
				setInputsConfig((s) => s.filter((cfg) => cfg.id !== configId));
			},
			changeAudioInputDevice: (
				/** @type {number} */configId,
				/** @type {string} */id,
				/** @type {string} */audioDeviceLabel,
			) => {
				setPartialInputsConfig({ id: configId, audioInputId: id, audioDeviceLabel });
			},
			changeVideoInputDevice: (
				/** @type {number} */configId,
				/** @type {string | undefined} */id,
				/** @type {string | undefined} */videoDeviceLabel,
			) => {
				setPartialInputsConfig({ id: configId, videoInputId: id, videoDeviceLabel });
			},
			changeConfigName: (
				/** @type {number} */configId,
				/** @type {string} */name,
			) => {
				setPartialInputsConfig({ id: configId, label: name });
			},
		};
	}, [
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		inputsConfig,
		videoInputDevices,
	]);

	const swapFrontAndBackCamera = useCallback(async () => {
		if (!isMobileOrIpad) {
			// eslint-disable-next-line no-console
			console.error('swapFrontAndBackCamera() is only available on mobile');
			return;
		}
		if (defaultFaceingMode === AVAILABLE_MOBILE_CAMS.FRONT) {
			const device = videoInputDevices.find(({ deviceId }) => deviceId === defaultBackDeviceId);
			helpers.changeVideoInputDevice(0, defaultBackDeviceId, device?.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
		} else {
			const device = videoInputDevices.find(({ deviceId }) => deviceId === defaultFrontDeviceId);
			helpers.changeVideoInputDevice(0, defaultFrontDeviceId, device?.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
		}
	}, [helpers, defaultBackDeviceId, defaultFrontDeviceId, videoInputDevices, defaultFaceingMode]);

	const setTargetCamera = useCallback((
		/** @type {AVAILABLE_MOBILE_CAMS} */TARGET,
	) => {
		if (TARGET !== AVAILABLE_MOBILE_CAMS.BACK
			&& TARGET !== AVAILABLE_MOBILE_CAMS.FRONT) {
			// eslint-disable-next-line no-console
			console.error('setTargetCamera() only accepts "back" or "front" as parameter');
			return;
		}
		if (TARGET === AVAILABLE_MOBILE_CAMS.BACK) {
			const device = videoInputDevices.find(({ deviceId }) => deviceId === defaultBackDeviceId);
			helpers.changeVideoInputDevice(0, defaultBackDeviceId, device?.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
		} else {
			const device = videoInputDevices.find(({ deviceId }) => deviceId === defaultFrontDeviceId);
			helpers.changeVideoInputDevice(0, defaultFrontDeviceId, device?.label);
			setDefaultFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
		}
	}, [defaultBackDeviceId, defaultFrontDeviceId, helpers, videoInputDevices]);

	const isPermissonGranted = useRef(false);
	const updateInputDevices = useCallback(async () => {
		const getCameraWithFaceingMode = async (
			/** @type {AVAILABLE_MOBILE_CAMS} */facingMode,
		) => {
			const constraints = { audio: true, video: { facingMode } };
			let mediastream;
			let deviceId;
			try {
				mediastream = await getUserMedia(constraints);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			} finally {
				if (mediastream) {
					deviceId = mediastream.getVideoTracks()[0].getSettings().deviceId;
					mediastream.getTracks().forEach((track) => track.stop());
				}
			}
			return deviceId;
		};

		const getFrontAndBackDeviceIds = async () => {
			const frontDeviceId = await getCameraWithFaceingMode(AVAILABLE_MOBILE_CAMS.FRONT);
			const backDeviceId = await getCameraWithFaceingMode(AVAILABLE_MOBILE_CAMS.BACK);
			return { frontDeviceId, backDeviceId };
		};

		/** @type {MediaDeviceInfo[]} */
		let devicesInfo = [];
		let mediastream;
		try {
			if (!isPermissonGranted.current) {
				isPermissonGranted.current = false;
				/* enumerateDevices() returns an incomplete result on Safari and Firefox
				until getUserMedia permission is granted.
				So we request user media just to execute enumerateDevices,
				then we stop the tracks to cancel the user media request. */
				mediastream = await getUserMedia({ audio: true, video: true });
				if (isMobileOrIpad) {
					const { frontDeviceId, backDeviceId } = await getFrontAndBackDeviceIds();
					setDefaultBackDeviceId(backDeviceId);
					setDefaultFrontDeviceId(frontDeviceId);
				}
				isPermissonGranted.current = true;
			}
			devicesInfo = await navigator.mediaDevices.enumerateDevices();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		} finally {
			if (mediastream) {
				mediastream.getTracks().forEach((track) => track.stop());
			}
		}

		/**
		 * @param {InputDeviceInfo} device
		 */
		const isDefaultDevice = (device) => !!devicesInfo.find((d) => (
			d.groupId === device.groupId
			&& (
				d.deviceId === DEFAULT_DEVICE_ID
				|| d.deviceId === ''
			)
		));

		/**
		 * @param {InputDeviceInfo} device
		 */
		const isVirtualDevice = (device) => {
			const upperLabel = (device.label || '').toUpperCase();
			if (upperLabel.includes('OBS ')) return true;
			if (upperLabel.includes('VIRTUAL')) return true;
			return false;
		};

		const uniqueDevicesInfo = devicesInfo
			// Firefox fix duplicates video input devices
			.filter(
				(device, index, self) => index === self.findIndex((d) => (
					d.deviceId === device.deviceId
					&& d.kind === device.kind
				)),
			)
			/* removing default device because it's duplicated
			so it could be chosen in thow different input configs */
			.filter((deviceInfo) => (
				deviceInfo.deviceId !== DEFAULT_DEVICE_ID
				&& deviceInfo.deviceId !== ''
			))
			.sort((a, b) => {
				// Keep order of different devices
				if (a.kind !== b.kind) { return 0; }

				// Devices that have a duplicate which has a "default" deviceId are first
				let aScore = isDefaultDevice(a) ? 1 : 0;
				let bScore = isDefaultDevice(b) ? 1 : 0;

				const diff = bScore - aScore;
				if (diff !== 0) return diff;

				// Virtual devices are last
				aScore = isVirtualDevice(a) ? 0 : 1;
				bScore = isVirtualDevice(b) ? 0 : 1;

				return bScore - aScore;
			})
			.map((deviceInfo) => ({
				deviceId: deviceInfo.deviceId,
				groupId: deviceInfo.groupId,
				kind: deviceInfo.kind,
				label: deviceInfo.label || deviceInfo.deviceId || DEFAULT_DEVICE_LABEL,
			}));

		setInputDevices(uniqueDevicesInfo);
	}, []);

	useEffect(() => {
		const handleDeviceChange = () => { updateInputDevices(); };

		/* "enabled" flag to avoid asking permission on safari until the Media component is enabled.
		See Media component. */
		if (enabled) {
			if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
				navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
			}

			updateInputDevices();
		}

		return () => {
			if (navigator.mediaDevices && navigator.mediaDevices.removeEventListener) {
				navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
			}
		};
	}, [enabled, updateInputDevices]);

	// Store input config in localstorage
	useEffect(() => { saveInputsConfig(inputsConfig); }, [inputsConfig]);

	useEffect(() => {
		const defaultInputConfig = inputsConfig?.find(({ id }) => id === DEFAULT_CONFIG_ID);

		if (!defaultInputConfig) {
			setInputsConfig((s) => [DEFAULT_INPUT_CONFIG, ...s]);
		}
	}, [videoInputDevices, audioInputDevices, inputsConfig, helpers]);

	const value = useMemo(() => ({
		...helpers,
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		defaultFaceingMode,
		getAvailableInputId,
		inputsConfig,
		setDefaultFaceingMode,
		setEnabled,
		setInputDevices,
		setInputsConfig,
		setTargetCamera,
		swapFrontAndBackCamera,
		updateInputDevices,
		videoInputDevices,
	}), [
		audioInputDevices,
		currentlyEnabledDefaultDevices,
		defaultFaceingMode,
		getAvailableInputId,
		helpers,
		inputsConfig,
		setDefaultFaceingMode,
		setTargetCamera,
		swapFrontAndBackCamera,
		updateInputDevices,
		videoInputDevices,
	]);

	return (
		<InputsContext.Provider value={value}>
			{children}
		</InputsContext.Provider>
	);
};

Inputs.propTypes = {
	children: PropTypes.node.isRequired,
};
