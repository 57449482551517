// @ts-check

import { createContext, useContext } from 'react';

export const DEFAULT_ITEMS_PER_PAGE = 20;
export const ITEMS_PER_PAGE_OPTIONS = [
	5, 10, 20, 50, 100, -1,
];

/**
 * @typedef {{
* currentPage: number,
* itemsPerPage: number,
* itemsCount: number,
* pageCount: number,
* canNextPage: boolean,
* canPrevPage: boolean,
* goNextPage: () => void,
* goPrevPage: () => void,
* goFirstPage: () => void,
* goLastPage: () => void,
* goPage: (page: number, forceChange?: boolean) => void,
* changeItemsPerPage: (newItemsPerPage: number) => void,
* updateItemsCount: (newItemsCount: number) => void,
* }} IPaginationContext
*/

export const PaginationContext = createContext(
	/** @type {IPaginationContext} */({}),
);

export const usePagination = () => {
	const paginationContext = useContext(PaginationContext);
	// type guard (removes undefined type)
	if (!paginationContext) {
		throw new Error('usePagination must be used within a PaginationProvider');
	}
	return paginationContext;
};
