/* eslint-disable react/prop-types */
// @ts-check

import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LinkedSocialNetworksContext } from './Context';
import { SocialNetworkPlatform } from '../../api/channel/socialNetworks.dto';

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} LinkedSocialNetworksProviderProps
 */

/** @type {React.FC<LinkedSocialNetworksProviderProps>} */
export const LinkedSocialNetworksProvider = ({ children }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [platformAccessToken, setPlatformAccessToken] = useState(
		/** @typedef {{ [key: SocialNetworkPlatform]: string }} */({}),
	);

	useEffect(() => {
		const code = searchParams.get('code');
		if (code) {
			setPlatformAccessToken({ [SocialNetworkPlatform.INSTAGRAM]: code });
			setSearchParams((prev) => {
				prev.delete('code');
				return prev;
			});
		}
	}, [searchParams, setSearchParams]);

	const value = useMemo(() => ({
		platformAccessToken,
		setPlatformAccessToken,
	}), [
		platformAccessToken,
		setPlatformAccessToken,
	]);

	return (
		<LinkedSocialNetworksContext.Provider value={value}>
			{children}
		</LinkedSocialNetworksContext.Provider>
	);
};
