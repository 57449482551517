// @ts-check

import { api } from '../api';

/**
 * @import { SocialNetworkPlatform } from './socialNetworks.dto';
 */

export const API_CHANNEL_SOCIAL_NETWORKS_PATH = '/channel/socialnetworks';

/**
 * @param {{
 * 		platform: SocialNetworkPlatform,
 * 		code: string,
 * }} param0
 * @returns {Promise<any>}
 */
// TODO : alexis define the return type (DTO)
export const linkSocialNetwork = async ({ platform, code }) => api.post(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/${platform}`,
	{ code },
);

export const fetchSocialNetworks = async () => api.get(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}`,
);

export const uploadVideoToSocialNetwork = async ({ platform, videoId }) => api.post(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/${platform}/videos/${videoId}`,
);

export const fetchVideoWithSocialNetworkUploads = async (videoId) => api.get(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/videos/${videoId}`,
);
