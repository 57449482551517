// @ts-check

import { createContext, useContext } from 'react';

/** @import { SocialNetworkPlatform } from '../../api/channel/socialNetworks.dto'; */

/**
 * @typedef {{
 * 	platformAccessToken: { [key: SocialNetworkPlatform]: string },
 *  setPlatformAccessToken: React.Dispatch<
 * 		React.SetStateAction<{ [key: SocialNetworkPlatform]: string }>
 * 	>,
 * }} ILinkedSocialNetworksContext
 */

export const LinkedSocialNetworksContext = createContext(
	/** @type {ILinkedSocialNetworksContext} */({}),
);

export const useLinkedSocialNetworks = () => useContext(LinkedSocialNetworksContext);
