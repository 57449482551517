// @ts-check

/** @enum {string} */
export const SocialNetworkPlatform = {
	INSTAGRAM: 'INSTAGRAM',
	FACEBOOK: 'FACEBOOK',
	TIKTOK: 'TIKTOK',
};

// the following line is to avoid the error "is not a module"
// eslint-disable-next-line no-underscore-dangle
export const __typeOnly = true;
