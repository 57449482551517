// @ts-check

import { createContext, useContext, useEffect } from 'react';

/**
 * @typedef {{
 *  	path?: string,
 * 		clear: () => void,
 *	}} IAutoComponentBrowserContext
 */

export const AutoComponentBrowserContext = createContext(
	/** @type {IAutoComponentBrowserContext} */({}),
);

export const AUTO_COMPONENT_BROWSER_KEY = 'beeyou_autoComponentBrowser_lastComponent';

export const autoComponentBrowserWrite = (/** @type {string} */ value) => {
	localStorage.setItem(AUTO_COMPONENT_BROWSER_KEY, value);
};

/**
 * @param {{
 * 		path: string,
 * 		cb: (storedPath: string) => boolean,
 *    	clearAfterBrowse?: boolean
 * }} param0
 *
 * This hook is used to browse components through the app automatically.
 * It will read a path you've set before from the local storage and your components
 * can use it to perform some actions based on the path, using the callback function.
 *
 * (e.g. it's used to open a modal when returning from Instagram
 * authorization page when linking an account)
 */
export const useAutoComponentBrowser = ({ path, cb, clearAfterBrowse = false }) => {
	const { path: storedPath, clear } = useContext(AutoComponentBrowserContext);

	useEffect(() => {
		if (storedPath && storedPath.startsWith(path)) {
			const cbSuccess = cb(storedPath);
			if (clearAfterBrowse && cbSuccess) clear();
		}
	}, [cb, clear, clearAfterBrowse, storedPath, path]);
};
