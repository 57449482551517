import { createContext, useContext } from 'react';

export const UserSettingsContext = createContext();

export const useUserSettings = () => useContext(UserSettingsContext);

export const UserSettingsModal = {
	ACCOUNT: 'ACCOUNT',
	GFX_LIBRARY: 'GFX_LIBRARY',
	VIDEO_IMAGE_LIBRARY: 'VIDEO_IMAGE_LIBRARY',
	MEMBERSHIP: 'MEMBERSHIP',
	LIBRARY: 'LIBRARY',
	INFO_CENTER: 'INFO_CENTER',
	KEY_LIBRARY: 'GREENKEY_LIBRARY',
	SELECT_VIDEOS: 'SELECT_VIDEOS',
	SELECT_IMAGES: 'SELECT_IMAGES',
	SURVEY_POLL: 'SURVEY_POLL',
	ANALYTICS_VOD_VIEW_MORE: 'ANALYTICS_VOD_VIEW_MORE',
	ANALYTICS_CHANNEL_VIEW_MORE: 'ANALYTICS_CHANNEL_VIEW_MORE',
	AD_CAMPAIGN_VIEW_MORE: 'AD_CAMPAIGN_VIEW_MORE',
};

export const InfoCenterModalTab = {
	TERMS_AND_PRIVACY: 'TERMS_AND_PRIVACY',
	COOKIE_POLICY: 'COOKIE_POLICY',
	HELP: 'HELP',
};

export const ViewPricesModal = {
	PRICES: 'PRICES',
};

export const MyAccountModalTab = {
	SETTINGS: 'SETTINGS',
	DETAILS: 'DETAILS',
	LINKED_SOCIAL_NETWORKS: 'LINKED_SOCIAL_NETWORKS',
	OPERATORS: 'OPERATORS',
	QRCODE: 'QRCODE',
	PREFERENCES: 'PREFERENCES',
	BLOCKED_CHANNELS: 'BLOCKED_CHANNELS',
};

export const SurveyVoteTemplateTab = {
	SURVEY: 'SURVEY',
	VOTE_TEMPLATE: 'VOTE_TEMPLATE',
};

export const ResourceStatus = {
	ACTIVE: 'Active',
	ARCHIVED: 'Archived',
};

export const AnalyticsAndAdvertisingModalTab = {
	ANALYTICS: 'ANALYTICS',
	ADVERTISING: 'ADVERTISING',
};
